@import '../../styles/settings';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.StepVisual {
    &__title {
        margin-bottom: rem(60);
        @include media-breakpoint-down(sm) {
            margin-bottom: rem(30);
        }
    }
    &__image {
        max-width: 100%;
    }
}