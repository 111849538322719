@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.Logo {
  display: inline-block;
  &__link {
    display: inline-block;
  }
  @include media-breakpoint-down(sm) {
    width: 205px;
  }
  @include media-breakpoint-down(xs) {
    width: 180px;
  }
}
