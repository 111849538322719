.custom-button {
  display:inline-flex;
  font-weight: 500;
 
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid #028283;
  background-color: #028283;
  color: #fff;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 1px;
  padding: 10px 20px;  // Added padding as part of the button styles

  &:hover {
    background-color: #026f6b; // Darker shade for hover effect
    border-color: #026f6b;
  }

  &:active {
    background-color: #025a58; // Even darker shade for active state
    border-color: #025a58;
  }

  &:focus {
    outline: none; // Remove default focus outline
    box-shadow: 0 0 0 3px rgba(2, 130, 131, 0.5); // Add custom focus outline
  }
}

@media (max-width: 768px) {

    h1 {
      font-size: 0.80rem; /* Adjust font size for tablets */
    }
  
  .container {
    flex-direction: column;
  }

  .left-section, .right-section {
    margin: 0;
    flex: 1;
  }

  .banner-text {
    font-size: 1rem !important;
    top: 70% !important;
    left: 50% !important;
  }

  input[type="text"] {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  

  .cust-care h2, .cust-care a {
    font-size: 1.1rem !important; 
  }

  .cust-care {
    flex-direction: row;
    align-items: flex-start;
  }
}



.header-banner-container .banner-container {
  position: relative;
  width: 100%;
  overflow: hidden; /* Ensures content doesn’t overflow */
}

.header-banner-container .banner-image {
  width: 100%;
  height: auto;
  display: block;
}

.header-banner-container .banner-text {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: '#008282';
    font-size: '4rem'; // Adjust this size
    font-weight: 'bold';
    text-shadow: '2px 2px 5px rgba(0, 0, 0, 0.7)';
}






