@import '../../../styles/settings.scss';

/* CUSTOM CHECKBOX */

.custom-checkbox {
  position: relative;
  font-size: 1rem;

  [type='checkbox']:not(:checked),
  [type='checkbox']:checked {
    position: absolute;
    left: -9999px;
    display: none;
  }

  [type='checkbox']:not(:checked) + label {
    color: $gray-400;
  }
  [type='checkbox']:checked + label {
    color: $gray-900;
  }

  [type='checkbox']:not(:checked) + label,
  [type='checkbox']:checked + label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    html[dir='ltr'] & {
      padding-left: 27px;
    }
    html[dir='rtl'] & {
      padding-right: 27px;
    }
  }
  [type='checkbox']:not(:checked) + label:before,
  [type='checkbox']:checked + label:before {
    content: '';
    position: absolute;
    top: 4px;
    width: 15px;
    height: 15px;
    border: 1px solid #d9d9d9;
    background: #fff;
    border-radius: 3px;
    html[dir='ltr'] & {
      left: 0;
    }
    html[dir='rtl'] & {
      right: 0;
    }
  }
  [type='checkbox']:not(:checked) + label:after,
  [type='checkbox']:checked + label:after {
    position: absolute;
    content: "\e929";
    top: 1px;
    font-family: 'icomoon' !important;
    color: #fff;
    transition: all 0.2s;
    font-weight: 700;
    font-size: 9px;
    html[dir='ltr'] & {
      left: 4px;
    }
    html[dir='rtl'] & {
      right: 4px;
    }
  }
  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
  }
  [type='checkbox']:checked + label:before {
    border-color: $primary;
    background-color: $primary;
  }
  [type='checkbox']:checked + label:after {
    opacity: 1;
  }
}
