@import 'styles/settings';

.ClickLink {
  text-decoration: none;
  color: $gray-400;
  font-size: rem(14);
  border: none;
  background: none;
  padding: 0;
  display: inline-flex;
  align-items: center;

  &--underline {
    text-decoration: underline;
  }
  &--primary {
    color: $primary;
  }
  &--small {
    font-size: 13px;
  }
  &--medium {
    font-size: 1rem;
    // color: #262626;
    // margin-bottom: 10px;
  }
  i {
    html[dir='ltr'] & {
      margin-right: rem(10);
    }
    html[dir='rtl'] & {
      margin-left: rem(10);
    }
  }
}
